import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import TableService from "../core/service/table.service";
import TableListStyle from "./styles/table-list.style";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import TableComponent from "../core/components/table.component";
import { useSocket } from "../core/context/socket.context";
import useScreenSize from "../core/hooks/use-screen-size";
import useUserOs from "../core/hooks/use-user-os";

export default function TableListComponent({
  searchVal,
}: {
  searchVal?: string;
}) {
  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const innerContainerSize = screenSize.height - (isIOS ? 240 : 225); // magic numbers

  const navigate = useNavigate();
  const classes = TableListStyle({});
  const tableService = new TableService();
  const [tableFilters, setTableFilters] = useState({
    searchString: searchVal,
    take: Math.floor((innerContainerSize - 100) / 68),
    skip: 0,
  });
  const { tableSocket } = useSocket();

  useEffect(() => {
    setTableFilters({
      ...tableFilters,
      searchString: searchVal,
      take: tableFilters.take,
      skip: 0,
    });
  }, [searchVal]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "",
      flex: 1,
      hideable: false,
      sortable: false,
      headerClassName: "hidden",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <Typography className={classes.pos} color="textSecondary">
              {params.row.name}
            </Typography>
            <Typography className={classes.subpos} color="textSecondary">
              {params.row.players ? params.row.players.length : 0} players
            </Typography>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (tableSocket) {
      tableSocket.on("tableCreated", () => {
        setTableFilters(tableFilters);
      });
      tableSocket.on("tableUpdated", () => {
        setTableFilters(tableFilters);
      });

      return () => {
        tableSocket.off("tableCreated");
        tableSocket.off("tableUpdated");
      };
    }
  }, [tableSocket]);

  const handleRowClick = (params: any) =>
    navigate(`/tables/${params.row.id}/view`);

  return (
    <Grid item xs={12} className={classes.wrapper}>
      <TableComponent
        height={innerContainerSize}
        columns={columns}
        service={tableService}
        filters={tableFilters}
        onRowClick={handleRowClick}
      />
    </Grid>
  );
}
