import GameInterface from "@homegame/common/dist/interface/game.interface";
import { useEffect, useState } from "react";
import { useAuth } from "../core/context/auth.context";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";

dayjs.extend(dayjsDuration);

interface GameDurationProps {
  game?: GameInterface;
  textVariant?:
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | undefined;
}

/**
 * A component that displays the duration of a game. If the game is ongoing, it will update every second.
 *
 * @param {GameInterface} game The game object.
 * @param {TypographyVariant} [textVariant="h5"] The Material-UI `Typography` variant.
 * @returns {React.ReactElement} A `Typography` element displaying the game duration.
 */
export default function GameDuration({
  game,
  textVariant = "h5",
}: GameDurationProps) {
  const { user } = useAuth();
  const [durationString, setDurationString] = useState<string>("");

  useEffect(() => {
    const setDuration = () => {
      if (!game) return;

      let duration = dayjs.duration(dayjs().diff(dayjs(game.startedAt)));
      if (game.status === GameStatus.FINISHED) {
        duration = dayjs.duration(
          dayjs(game.finishedAt).diff(dayjs(game.startedAt)),
        );
      }
      if (game.status === GameStatus.ONGOING) {
        duration = dayjs.duration(dayjs().diff(dayjs(game.startedAt)));
      }

      const months = Math.floor(duration.asMonths());
      const days = Math.floor(duration.asDays() % 30);
      const hours = Math.floor(duration.asHours() % 24);
      const minutes = Math.floor(duration.minutes());
      const seconds = Math.floor(duration.seconds());

      let durationString = "";
      if (months > 0)
        durationString += `${months} month${months > 1 ? "s" : ""} `;
      if (days > 0) durationString += `${days} day${days > 1 ? "s" : ""} `;
      durationString += `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      setDurationString(durationString);
    };

    setDuration();

    if (game?.status === GameStatus.FINISHED) return;

    const interval = setInterval(setDuration, 1000);
    return () => clearInterval(interval);
  }, [game]);

  if (!game || !user || !game.startedAt) return <></>;

  return (
    <Typography
      variant={textVariant}
      gutterBottom
      style={{ textAlign: "center", textTransform: "none" }}
    >
      {durationString}
    </Typography>
  );
}
