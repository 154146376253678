import TransactionsListComponent from "../transactions-list.component";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../core/context/auth.context";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../core/hooks/use-screen-size";
import useUserOs from "../../core/hooks/use-user-os";
import { useState } from "react";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import Button from "@mui/material/Button";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import IconButton from "@mui/material/IconButton";
// import ClearIcon from "@mui/icons-material/Clear";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  DateRange,
  DateRangePicker,
} from "../../core/components/date-range-picker";
import { defaultRanges } from "../../core/components/date-range-picker/defaults";
// import makeStyles from "@mui/styles/makeStyles";
// import createStyles from "@mui/styles/createStyles";
// import PdfTransactionsComponent from "../../core/components/pdf-transactions.component";

// const useStyles = makeStyles(() =>
//   createStyles({
//     btnsContainer: {
//       display: "flex",
//       height: 55,
//       backgroundColor: "#4d5387",
//       padding: 10,
//       justifyContent: "center",
//     },
//     btn: {
//       border: "1px solid #fff",
//       color: "#fff",
//       "& .MuiButtonBase-root": { color: "#fff" },
//       "& .MuiButton-root": {
//         color: "#fff",
//         fontSize: 13,
//         textTransform: "none",
//         "& .MuiSvgIcon-root": {
//           marginRight: 10,
//           marginLeft: 5,
//         },
//       },
//     },
//   }),
// );

export default function TabAllPayments() {
  // const classes = useStyles({});

  const { user } = useAuth();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const [open, setOpen] = useState(false);
  const [, setDateRange] = useState<DateRange>(defaultRanges[2]);

  const toggle = () => setOpen(!open);

  // const getDatesString = () => {
  //   const { label, startDate, endDate } = dateRange;
  //   if (label) return label;
  //   if (!startDate || !endDate) return "Select date";
  //   return `${startDate.format("DD MMM")} - ${endDate.format("DD MMM")}`;
  // };

  // const prevRange = () => {
  //   const { startDate, endDate } = dateRange;
  //   if (!startDate || !endDate) return;
  //   const daysDiff = startDate!.diff(endDate, "days");
  //   setDateRange({
  //     startDate: startDate.subtract(daysDiff, "days"),
  //     endDate: endDate.subtract(daysDiff, "days"),
  //   });
  // };

  // const nextRange = () => {
  //   const { startDate, endDate } = dateRange;
  //   if (!startDate || !endDate) return;
  //   const daysDiff = startDate!.diff(endDate, "days");
  //   setDateRange({
  //     startDate: startDate.add(daysDiff, "days"),
  //     endDate: endDate.add(daysDiff, "days"),
  //   });
  // };

  const changeRange = (range: DateRange) => {
    setDateRange(range);
    toggle();
  };

  // const clearDate = () => {
  //   setDateRange(defaultRanges[2]);
  // };

  const innerContainerSize = screenSize.height - (isIOS ? 240 : 225); // magic numbers
  // const innerContainerSize = screenSize.height - (isIOS ? 260 : 245); // magic numbers

  const onTransactionStatusChanged = () => {
    navigate(0);
  };

  return (
    <>
      {/*<Grid item sm={12} xs={12} className={classes.btnsContainer}>*/}
      {/*  <ButtonGroup*/}
      {/*    variant="outlined"*/}
      {/*    aria-label="text button group"*/}
      {/*    style={{ marginRight: 40 }}*/}
      {/*  >*/}
      {/*    <Button onClick={prevRange} className={classes.btn}>*/}
      {/*      <ChevronLeftIcon sx={{ fontSize: 17 }} />*/}
      {/*    </Button>*/}
      {/*    <div className={classes.btn}>*/}
      {/*      <Button variant="text" onClick={() => setOpen(true)}>*/}
      {/*        /!*<p>*!/*/}
      {/*        <CalendarMonthIcon sx={{ fontSize: 17 }} />*/}
      {/*        {getDatesString()}*/}
      {/*        /!*</p>*!/*/}
      {/*      </Button>*/}
      {/*      <IconButton*/}
      {/*        onClick={clearDate}*/}
      {/*        color="primary"*/}
      {/*        aria-label="upload picture"*/}
      {/*        component="span"*/}
      {/*      >*/}
      {/*        <ClearIcon*/}
      {/*          style={{ position: "relative", zIndex: 9 }}*/}
      {/*          sx={{ fontSize: 17 }}*/}
      {/*        />*/}
      {/*      </IconButton>*/}
      {/*    </div>*/}
      {/*    <Button onClick={nextRange} className={classes.btn}>*/}
      {/*      <ChevronRightIcon sx={{ fontSize: 17 }} />*/}
      {/*    </Button>*/}
      {/*  </ButtonGroup>*/}

      {/*  <PdfTransactionsComponent*/}
      {/*    dateFrom={dateRange.startDate?.utc().format()}*/}
      {/*    dateTo={dateRange.endDate?.utc().format()}*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <br />*/}
      {/*</Grid>*/}
      <Grid
        item
        xs={12}
        height="80vh"
        style={{
          backgroundColor: "#aab1db",
          boxShadow: "1px 1px 3px rgb(0 0 0 / 40%)",
          padding: 15,
          borderRadius: 5,
        }}
      >
        <TransactionsListComponent
          height={innerContainerSize}
          userId={user?.id}
          showStatus
          pageSize={Math.floor((innerContainerSize - 100) / 80)}
          showTableName
          onActionDone={onTransactionStatusChanged}
          // filters={{
          //   dateFrom: dateRange.startDate?.utc().format(),
          //   dateTo: dateRange.endDate?.utc().format(),
          // }}
        />
        <DateRangePicker open={open} toggle={toggle} onChange={changeRange} />
      </Grid>
    </>
  );
}
