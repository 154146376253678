import dayjs from "dayjs";
import { DefinedRange } from "./types";

const getDefaultRanges = (): DefinedRange[] => [
  {
    label: "Today",
    startDate: dayjs().startOf("day"),
    endDate: dayjs().endOf("day"),
  },
  {
    label: "Yesterday",
    startDate: dayjs().add(-1, "day").startOf("day"),
    endDate: dayjs().add(-1, "day").endOf("day"),
  },
  {
    label: "This Week",
    startDate: dayjs().startOf("week"),
    endDate: dayjs().endOf("week"),
  },
  {
    label: "Last Week",
    startDate: dayjs().subtract(1, "week").startOf("week"),
    endDate: dayjs().subtract(1, "week").endOf("week"),
  },
  {
    label: "Last 7 Days",
    startDate: dayjs().subtract(1, "week"),
    endDate: dayjs(),
  },
  {
    label: "This Month",
    startDate: dayjs().startOf("month"),
    endDate: dayjs().endOf("month"),
  },
  {
    label: "Last Month",
    startDate: dayjs().subtract(1, "month").startOf("month"),
    endDate: dayjs().subtract(1, "month").endOf("month"),
  },
];

export const defaultRanges = getDefaultRanges();
