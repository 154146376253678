import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import GameService from "../../core/service/game.service";
import { useAuth } from "../../core/context/auth.context";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import createStyles from "@mui/styles/createStyles";
import Button from "@mui/material/Button";
import SelectCurrency from "../../core/components/select-currency.component";
import InputAdornment from "@mui/material/InputAdornment";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableInterface from "@homegame/common/dist/interface/table.interface";
import { useNavigate } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { DateInput } from "../../core/components/date-picker.component";
import LoadingButton from "../../core/components/loading-button.component";
import Grid from "@mui/material/Grid";
import IntInputComponent from "../../core/components/int-input.component";
import { useToast } from "../../core/hooks/use-toast.hook";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import parseErrors from "../../core/helpers/parse-from-errors.helper";

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: "#fff",
      fontWeight: 500,
      fontSize: 14,
      marginTop: 5,
    },
    title: {
      color: "#fff",
      fontWeight: 300,
      textAlign: "center",
      borderBottom: "2px solid rgba(245,0,87,.6)",
    },
    btn: { width: "50%", marginTop: 10 },
    chipText: {
      margin: 0,
      color: "#fff",
      paddingTop: 5,
      fontWeight: "bold",
      paddingLeft: 8,
    },
    chipInput: {
      "& .MuiTypography-root": {
        color: "#fff",
      },
      "& .MuiInputBase-input ": {
        paddingLeft: 15,
      },
    },
    numberInput: {
      "& .MuiFilledInput-root": {
        background: "transparent",
        paddingTop: 5,
      },
      "& .MuiTypography-root": {
        color: "#fff",
      },
      "& .MuiInputBase-input ": {
        textAlign: "center",
      },
      "& .MuiFormControl-root ": {},
    },
    datePicker: {
      "& .MuiInputBase-root": { backgroundColor: "transparent" },
    },
    datePickerDayRoot: { color: "white" },
    datePickerDayDisabled: { color: "rgba(255, 255, 255, .5) !important" },
    errorText: {
      backgroundColor: "red",
      color: "#fff !important",
      fontSize: 12,
      textShadow: "none",
      padding: "5px 15px",
      borderRadius: 5,
      marginLeft: 0,
    },
    checkBoxControl: {
      color: "#fff",
      "& .MuiTypography-root": {
        fontSize: 14,
      },
      "& .MuiCheckbox-root .MuiSvgIcon-root  ": {
        fontSize: 16,
        // width: 14,
        // height: 14,
      },
    },
  }),
);

const schema = yup
  .object({
    moneyValue: yup
      .number()
      .positive("Must be a positive number")
      .required("Money value is required")
      .typeError("Money value is required"),
    chipValue: yup
      .number()
      .positive("Must be a positive number")
      .required("Chip value is required")
      .typeError("Chip value is required"),
    currency: yup
      .string()
      .required("Currency is required")
      .typeError("Currency value is required"),
    date: yup
      .string()
      .required("Date is required")
      .typeError("Date is required"),
    minimumBuyIn: yup
      .number()
      .positive("Minimum buy-in must be a positive number")
      .required("Minimum buy-in is required"),
    maximumBuyIn: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .optional()
      .test(
        "is-greater",
        "Maximum buy-in must be greater than minimum buy-in",
        function (value) {
          if (!value) return true;
          const { minimumBuyIn } = this.parent;
          return value > minimumBuyIn;
        },
      ),
    playersLimit: yup
      .number()
      .min(2, "Number of players can't be less than 2")
      .required("Required"),
    minimumMngApproveTime: yup
      .number()
      .min(0, "Must be at least 0")
      .required("Required")
      .typeError("Chip value is required"),
    useDynamicBuyIn: yup.boolean().default(false),
    buyInRules: yup
      .array()
      .of(
        yup.object({
          hoursAfterStart: yup
            .number()
            .min(0, "Must be 0 or more")
            .integer("Must be number")
            .required("Hours after start is required"),
          minimumBuyIn: yup
            .number()
            .positive("Must be a positive number")
            .required("Minimum buy-in is required"),
        }),
      )
      .length(2),
  })
  .required();

export interface CreateGameDialogProps {
  onClose?: () => any;
  table?: TableInterface;
  userId: number;
  initialValues?: any;
}

export default function CreateGameDialog({
  onClose,
  table,
  userId,
  initialValues,
}: CreateGameDialogProps) {
  const { showToast } = useToast();
  const [requestErorr, setRequestError] = useState<string>("");

  const classes: any = useStyles({});
  const navigate = useNavigate();

  if (initialValues?.date) initialValues.date = new Date(initialValues?.date);

  const gameService = new GameService();
  const { user } = useAuth();

  const methods = useForm({
    defaultValues: initialValues || {
      currency: "USD",
      moneyValue: 1,
      minimumBuyIn: 10,
      maximumBuyIn: 100,
      chipValue: 1,
      playersLimit: 10,
      minimumMngApproveTime: 5,
      date: new Date(),
      useDynamicBuyIn: false,
      buyInRules: [
        { hoursAfterStart: 0, minimumBuyIn: 10, maximumBuyIn: "" },
        { hoursAfterStart: 1, minimumBuyIn: 20, maximumBuyIn: "" },
      ],
    },
    resolver: yupResolver(schema),
  });

  const { errors } = methods.formState;

  const friendlyFieldNames: { [key: string]: string } = {
    moneyValue: "Money Value",
    chipValue: "Chip Value",
    currency: "Currency",
    date: "Game Date",
    minimumBuyIn: "Minimum Buy-In",
    maximumBuyIn: "Maximum Buy-In",
    playersLimit: "Players Limit",
    minimumMngApproveTime: "Minimum Manager Approval Time",
    "buyInRules.0.hoursAfterStart": "First Hours After Start",
    "buyInRules.0.minimumBuyIn": "Minimum Buy-In for Initial Period",
    "buyInRules.1.hoursAfterStart": "After Initial Period Hours",
    "buyInRules.1.minimumBuyIn": "Minimum Buy-In After Initial Period",
  };

  const parsedErrors = parseErrors(errors, {
    fieldNames: friendlyFieldNames,
  });

  const [isSaving, setIsSaving] = useState<boolean>(false);
  useFieldArray({
    control: methods.control,
    name: "buyInRules",
  });
  const [useDynamicBuyIn, setUseDynamicBuyIn] = useState<boolean>(
    initialValues?.useDynamicBuyIn || false,
  );

  const onSubmit = async (userData: any) => {
    try {
      setRequestError("");
      if (!user) return;
      const newData = { ...userData, useDynamicBuyIn };
      newData.userId = initialValues?.userId || userId;
      newData.tableId = initialValues?.tableId || table?.id;
      setIsSaving(true);

      const createdGame = await gameService.save(newData, initialValues?.id);
      navigate(`/game/${createdGame.id}`);
      if (onClose) onClose();
      if (initialValues && initialValues.id) {
        showToast("Game saved.", "success");
      }
    } catch (e: any) {
      console.log(e);
      setRequestError(e.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog onClose={handleClose} open={true} scroll="paper">
      <DialogTitle id="game-dialog-title" className={classes.title}>
        {initialValues?.id ? "Edit" : "Create"} game
      </DialogTitle>

      <FormProvider {...methods}>
        <DialogContent style={{ padding: "0px 24px" }}>
          <form onSubmit={methods.handleSubmit(onSubmit)} id="createGameForm">
            <br />
            <Grid container>
              <Grid item xs={12} mt={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      Set the chip value
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControl style={{ width: 50 }}>
                      {/*@ts-ignore*/}
                      <Controller
                        render={({ field }) => (
                          <TextField
                            type="number"
                            variant="standard"
                            {...field}
                            fullWidth
                            disabled={isSaving}
                          />
                        )}
                        name="moneyValue"
                        control={methods.control}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl style={{ width: 75 }}>
                      <Controller
                        render={({ field }) => (
                          <SelectCurrency {...field} disabled={isSaving} />
                        )}
                        name="currency"
                        control={methods.control}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <p className={classes.chipText}>=</p>
                  </Grid>
                  <Grid item>
                    <FormControl style={{ width: 90 }}>
                      {/*@ts-ignore*/}
                      <Controller
                        render={({ field }) => (
                          <TextField
                            className={classes.chipInput}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  chip
                                </InputAdornment>
                              ),
                            }}
                            variant="standard"
                            {...field}
                            fullWidth
                            disabled={isSaving}
                          />
                        )}
                        name="chipValue"
                        control={methods.control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Typography className={classes.label}>Game date</Typography>

                <FormControl
                  fullWidth
                  style={{ display: "inline-block", zIndex: 99 }}
                >
                  <DateInput name="date" disablePast />
                </FormControl>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Typography className={classes.label}>
                  Max # of players
                </Typography>

                <FormControl
                  fullWidth
                  style={{ display: "inline-block", zIndex: 1 }}
                >
                  <Controller
                    render={({ field }) => (
                      <IntInputComponent
                        {...field}
                        disabled={isSaving}
                        min={2}
                        max={12}
                        variant="filled"
                      />
                    )}
                    name="playersLimit"
                    control={methods.control}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Typography className={classes.label}>
                  Min mng. approve time
                </Typography>

                <FormControl
                  fullWidth
                  style={{ display: "inline-block", zIndex: 1 }}
                >
                  <Controller
                    render={({ field }) => (
                      <IntInputComponent
                        {...field}
                        disabled={isSaving}
                        min={0}
                        max={60}
                        variant="filled"
                      />
                    )}
                    name="minimumMngApproveTime"
                    control={methods.control}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} mt={3}>
                <FormControl fullWidth className={classes.numberInput}>
                  <Grid container spacing={1} mt={1}>
                    <Grid item xs={12}>
                      <InputLabel
                        className={classes.label}
                        style={{ left: -15, top: -15 }}
                      >
                        Buy In (chips)
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                    >
                      <Typography
                        component="small"
                        fontSize={14}
                        lineHeight="17px"
                      >
                        Min
                      </Typography>
                      <Controller
                        render={({ field }) => (
                          <IntInputComponent
                            {...field}
                            disabled={isSaving || useDynamicBuyIn}
                            variant="filled"
                          />
                        )}
                        name="minimumBuyIn"
                        control={methods.control}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                    >
                      <Typography
                        component="small"
                        fontSize={14}
                        lineHeight="17px"
                      >
                        Max
                      </Typography>
                      <Controller
                        render={({ field }) => (
                          <IntInputComponent
                            {...field}
                            disabled={isSaving || useDynamicBuyIn}
                            variant="filled"
                            placeholder="No limit"
                          />
                        )}
                        name="maximumBuyIn"
                        control={methods.control}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        label="Enable Dynamic Buy-In"
                        className={classes.checkBoxControl}
                        control={
                          <Controller
                            name="useDynamicBuyIn"
                            control={methods.control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                style={{ color: "#fff" }}
                                id="useDynamicBuyIn"
                                checked={useDynamicBuyIn}
                                onChange={(e) => {
                                  setUseDynamicBuyIn(e.target.checked);
                                  field.onChange(e.target.checked);
                                }}
                              />
                            )}
                          />
                        }
                      />
                    </Grid>

                    {useDynamicBuyIn && (
                      <>
                        <Grid item xs={12}>
                          <Typography>Initial Period</Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          alignItems="end"
                          justifyContent="space-between"
                        >
                          <Typography
                            component="small"
                            fontSize={14}
                            lineHeight="17px"
                          >
                            Period start (hours)
                          </Typography>
                          <Controller
                            defaultValue={0}
                            render={({ field }) => (
                              <IntInputComponent
                                {...field}
                                disabled
                                variant="filled"
                              />
                            )}
                            name={`buyInRules[0].hoursAfterStart`}
                            control={methods.control}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          alignItems="end"
                          justifyContent="space-between"
                        >
                          <Typography
                            component="small"
                            fontSize={14}
                            lineHeight="17px"
                          >
                            Minimum Buy-In
                          </Typography>
                          <Controller
                            render={({ field }) => (
                              <IntInputComponent
                                {...field}
                                disabled={isSaving}
                                variant="filled"
                              />
                            )}
                            name={`buyInRules[0].minimumBuyIn`}
                            control={methods.control}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          alignItems="end"
                          justifyContent="space-between"
                        >
                          <Typography
                            component="small"
                            fontSize={14}
                            lineHeight="17px"
                          >
                            Maximum Buy-In
                          </Typography>
                          <Controller
                            render={({ field }) => (
                              <IntInputComponent
                                {...field}
                                disabled={isSaving}
                                variant="filled"
                                placeholder="No limit"
                              />
                            )}
                            name={`buyInRules[0].maximumBuyIn`}
                            control={methods.control}
                          />
                        </Grid>

                        <Divider style={{ width: "100%", margin: "20px 0 " }} />

                        <Grid item xs={12}>
                          <Typography>After Initial Period</Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          alignItems="end"
                          justifyContent="space-between"
                        >
                          <Typography
                            component="small"
                            fontSize={14}
                            lineHeight="17px"
                          >
                            Period start (hours)
                          </Typography>
                          <Controller
                            render={({ field }) => (
                              <IntInputComponent
                                {...field}
                                disabled={isSaving}
                                variant="filled"
                              />
                            )}
                            name={`buyInRules[1].hoursAfterStart`}
                            control={methods.control}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          alignItems="end"
                          justifyContent="space-between"
                        >
                          <Typography
                            component="small"
                            fontSize={14}
                            lineHeight="17px"
                          >
                            Minimum Buy-In
                          </Typography>
                          <Controller
                            render={({ field }) => (
                              <IntInputComponent
                                {...field}
                                disabled={isSaving}
                                variant="filled"
                              />
                            )}
                            name={`buyInRules[1].minimumBuyIn`}
                            control={methods.control}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          alignItems="end"
                          justifyContent="space-between"
                        >
                          <Typography
                            component="small"
                            fontSize={14}
                            lineHeight="17px"
                          >
                            Maximum Buy-In
                          </Typography>
                          <Controller
                            render={({ field }) => (
                              <IntInputComponent
                                {...field}
                                disabled={isSaving}
                                variant="filled"
                                placeholder="No limit"
                              />
                            )}
                            name={`buyInRules[1].maximumBuyIn`}
                            control={methods.control}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      {requestErorr && (
                        <FormHelperText className={classes.errorText} error>
                          {requestErorr}
                        </FormHelperText>
                      )}
                      {parsedErrors.length > 0 && (
                        <div style={{ color: "red", marginBottom: "10px" }}>
                          {parsedErrors.map((message, index) => (
                            <FormHelperText
                              className={classes.errorText}
                              error
                              key={index}
                            >
                              {message}
                            </FormHelperText>
                          ))}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <br />
          </form>
        </DialogContent>
        <DialogActions style={{ padding: "0 25px 25px 25px" }}>
          <Button
            className={classes.btn}
            style={{ backgroundColor: "#4d589c" }}
            variant="contained"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            text={`${initialValues ? "Edit" : "Create"} game`}
            className={classes.btn}
            variant="contained"
            isLoading={isSaving}
            color="secondary"
            type="submit"
            form="createGameForm"
          />
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
