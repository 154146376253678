import SystemSnackbarComponent from "../core/components/system-snackbar.component";
import SystemLoaderComponent from "../core/components/system-loader.component";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  container: {
    height: "100svh",
    paddingBottom: 70,
    overflow: "auto",
  },
});

export default function BasePage({ children }: any) {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.container}>{children}</Container>
      <SystemSnackbarComponent />
      <SystemLoaderComponent />
    </>
  );
}
