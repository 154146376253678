import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import GameService from "../core/service/game.service";
import GameDuration from "../game/game-duration";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";
import Chip from "@mui/material/Chip";
import GameInterface from "@homegame/common/dist/interface/game.interface";
import { useNavigate } from "react-router-dom";
import TableComponent from "../core/components/table.component";
import useScreenSize from "../core/hooks/use-screen-size";
import useUserOs from "../core/hooks/use-user-os";

const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      minWidth: "100%",
      paddingLeft: 0,
      "& .MuiDataGrid-footerContainer": {
        border: "none",
      },
      "& .MuiDataGrid-cell": {
        border: "none",
        padding: 10,
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
      },
      "& .MuiDataGrid-row": {
        width: "100%",
        marginBottom: 10,
        backgroundColor: "#4b5170",
        borderRadius: 5,
        textAlign: "left",
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "none",
      },
      "& .MuiDataGrid-root ": {
        border: "none",
      },
    },
    subpos: {
      marginBottom: 0,
      color: "#fff",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: 1,
      "& small": {
        color: "#fff",
        fontWeight: 500,
        fontSize: 12,
      },
    },
    status: {
      width: "100%",
      textAlign: "right",
      "& .MuiChip-label ": {
        color: "#fff",
        fontSize: 12,
      },
    },
  }),
);

interface GamesListProps {
  tableId?: number | undefined;
}

export default function GamesListComponent({ tableId }: GamesListProps) {
  const classes = useStyles({});
  const navigate = useNavigate();
  const gamesService = new GameService();

  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const innerContainerSize = screenSize.height - (isIOS ? 270 : 265); // magic numbers

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "",
      flex: 1,
      hideable: false,
      sortable: false,
      headerClassName: "hidden",
      renderCell: (params: GridRenderCellParams) => {
        const game = params.row;

        return (
          <>
            <Typography className={classes.subpos} color="textSecondary">
              <small>Game date: </small>
              {dayjs(game.date).format("D MMMM YYYY")}
            </Typography>
            {![GameStatus.NEW, GameStatus.CANCELED].includes(game.status) && (
              <Typography className={classes.subpos} color="textSecondary">
                <small>
                  Duration: <GameDuration game={game} textVariant="overline" />
                </small>
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "",
      flex: 0.5,
      hideable: false,
      sortable: false,
      headerClassName: "hidden",
      renderCell: (params: GridRenderCellParams) => {
        const game = params.row;
        return (
          <div className={classes.status}>
            <Chip label={game.status} />
          </div>
        );
      },
    },
  ];

  const onRowClick = (row: GridRowParams<GameInterface>) => {
    navigate(`/game/${row.row.id}`);
  };

  return (
    <Grid item xs={12} className={classes.wrap}>
      <TableComponent
        columns={columns}
        service={gamesService}
        filters={{
          take: Math.floor(innerContainerSize / 85),
          skip: 0,
          tableId,
        }}
        onRowClick={onRowClick}
        height={innerContainerSize}
      />
    </Grid>
  );
}
