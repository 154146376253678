import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import AuthService, { SignUpInterface } from "../core/service/auth.service";
import EmptyPage from "../core/empty.page";
import { useAuth } from "../core/context/auth.context";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInputComponent from "../core/components/phone-input.component";
import jwt_decode from "jwt-decode";
import UserService from "../core/service/user.service";
import { setCookie } from "../core/helpers/storage.helper";
import { REFRESH_TOKEN_COOKIE_NAME, TOKEN_COOKIE_NAME } from "../core/config";
import WrapperService from "../core/service/wrapper.service";
import { useToast } from "../core/hooks/use-toast.hook";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 500,
      margin: "0 auto",
      textAlign: "center",
    },
    image: {
      height: "auto",
      width: "80%",
      marginTop: 10,
    },
    heading: {
      fontSize: 30,
    },
    field: {
      width: "100%",
    },
    button: {
      width: "50%",
      marginTop: 30,
    },
  }),
);

export default function SignUpPage() {
  const classes = useStyles({});
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const navigate = useNavigate();
  const authService = new AuthService();
  const userService = new UserService();
  const wrapperService = new WrapperService();

  const { showToast } = useToast();
  const { setUser } = useAuth();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignUpInterface>({
    defaultValues: {
      name: "",
      phone: query.get("p") || "",
    },
  });

  const onSubmit = async (data: SignUpInterface) => {
    try {
      const { accessToken, refreshToken } = await authService.signUp(data);

      if (typeof accessToken === "undefined") showToast("No token", "error");

      const redirectURL = query.get("redirect") || "/tables";
      const redirectURLObj = new URL(redirectURL, window.location.origin);

      if (accessToken) {
        const decoded: any = jwt_decode(accessToken as string);

        setCookie(TOKEN_COOKIE_NAME, accessToken);
        setCookie(REFRESH_TOKEN_COOKIE_NAME, refreshToken);

        const profile = await userService.single(decoded.id);

        const redirectURLObj = new URL(redirectURL, window.location.origin);
        redirectURLObj.searchParams.set("userId", decoded.id);
        redirectURLObj.searchParams.set("action", "login");

        wrapperService.notifyLogin(decoded.id);

        setUser(profile);
      }

      navigate(redirectURLObj.href.replace(window.location.origin, ""));
    } catch (e: any) {
      showToast(e.message, "error");
    }
  };

  return (
    <EmptyPage>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        className={classes.container}
      >
        <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
          <img className={classes.image} src="/logo.png" alt="Login" />
          <Typography className={classes.heading} variant="h2" gutterBottom>
            Sign Up
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: "Name required!",
                minLength: {
                  value: 3,
                  message: "Min name length is 3",
                },
              }}
              render={({ field }) => (
                <TextField
                  type="name"
                  label="User Name"
                  variant="filled"
                  helperText={errors.name ? errors.name.message : ""}
                  error={!!errors.name}
                  className={classes.field}
                  {...field}
                />
              )}
            />

            <Controller
              name="phone"
              control={control}
              rules={{
                required: "Field is required",
              }}
              render={({ field }) => (
                <PhoneInputComponent
                  disabled={!!query.get("p")}
                  autofocus={true}
                  {...field}
                />
              )}
            />

            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Sign Up
            </Button>
          </form>
        </Grid>
      </Grid>
    </EmptyPage>
  );
}
