import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  appBar: {
    color: "#fff",
    backgroundColor: "transparent",
    boxShadow: "none",
    textAlign: "center",
    width: "100%",
    height: 85,
    maxWidth: 500,
    margin: "0 auto",
  },
  heading: {
    fontSize: "1.2rem",
    width: "100%",
    marginBottom: 0,
  },
  container: {
    height: "100svh",
    paddingBottom: 70,
    overflow: "auto",
  },
});

export default function ContentComponent({ children }: any) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.appBar} id="appName">
        <img height="100px" src="/logo.png" alt="Logo" />
      </div>
      <Container className={classes.container}>{children}</Container>
    </>
  );
}
