import TransactionsListComponent from "../transactions-list.component";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../core/context/auth.context";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../core/hooks/use-screen-size";
import useUserOs from "../../core/hooks/use-user-os";

export default function TabReceived() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const innerContainerSize = screenSize.height - (isIOS ? 240 : 225); // magic numbers

  const onTransactionStatusChanged = () => {
    navigate(0);
  };

  return (
    <Grid
      item
      xs={12}
      height="80vh"
      style={{
        backgroundColor: "#aab1db",
        boxShadow: "1px 1px 3px rgb(0 0 0 / 40%)",
        padding: 15,
        borderRadius: 5,
      }}
    >
      <TransactionsListComponent
        height={innerContainerSize}
        toUserId={user?.id}
        pageSize={Math.floor((innerContainerSize - 100) / 80)}
        showTableName
        type={TransactionType.PAYMENT}
        showStatus
        onActionDone={onTransactionStatusChanged}
      />
    </Grid>
  );
}
