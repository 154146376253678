import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import BalanceIcon from "@mui/icons-material/Balance";
import formatCurrency from "../../../core/helpers/format-currency.helper";
import CardComponent from "./card.component";
import { useGame } from "../../game.context";

interface CardOwesProps {
  debtToTable: number;
  isPlayerView?: boolean;
}

/**
 * A React component that displays a card showing the amount owed to or by a player.
 *
 * @param {number} debtToTable - The amount owed to or by the player.
 * @param {boolean} [isPlayerView=false] - Whether the card is being viewed from the player's perspective.
 * @return {JSX.Element} A CardComponent with the amount owed to or by the player and a corresponding icon.
 */
export default function CardOwesComponent({
  debtToTable,
  isPlayerView = false,
}: CardOwesProps) {
  const { game } = useGame();

  if (!game) return <></>;

  let textColor = "inherit";
  if (debtToTable < 0) textColor = "#63bc65";
  if (debtToTable > 0) textColor = "rgba(245,0,87,.9)";

  let finishBalanceText = (
    <span>{`${isPlayerView ? "You are" : "Player is"} not owed to/owes`}</span>
  );

  if (debtToTable < 0)
    finishBalanceText = (
      <span
        style={{
          color: textColor,
          fontWeight: "bold",
        }}
      >{`Owned to ${isPlayerView ? "you" : "player"}`}</span>
    );
  else if (debtToTable > 0)
    finishBalanceText = (
      <span
        style={{
          color: textColor,
          fontWeight: "bold",
        }}
      >{`${isPlayerView ? "You owe" : "Player owes"}`}</span>
    );

  let icon = <BalanceIcon />;
  if (debtToTable < 0) icon = <CreditScoreIcon style={{ color: textColor }} />;
  if (debtToTable > 0) icon = <ErrorRoundedIcon style={{ color: textColor }} />;

  let border = undefined;
  if (debtToTable < 0) border = "1px solid #63bc65";
  if (debtToTable > 0) border = "1px solid rgba(245,0,87,.9)";

  return (
    <CardComponent
      border={border}
      icon={icon}
      primary={
        <span
          style={{
            color: textColor,
          }}
        >
          {formatCurrency(Math.abs(debtToTable), game.currency)}
        </span>
      }
      secondary={finishBalanceText}
    />
  );
}
