import { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import { useAuth } from "../core/context/auth.context";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { useSocket } from "../core/context/socket.context";
import formatCurrency from "../core/helpers/format-currency.helper";
import UserDebtsService from "../core/service/user-debts.service";
import UserDebtFilterInterface from "@homegame/common/dist/interface/filters/user-debt-filter.interface";
import DebtStatus from "./components/debt-status.component";
import ConfirmationDialogComponent from "../core/components/confirmation-dialog.component";
import DoneIcon from "@mui/icons-material/Done";
import { useToast } from "../core/hooks/use-toast.hook";
import UserDebtStatus from "@homegame/common/dist/enum/user-debt-status.enum";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ListItemIcon, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import UserDebtInterface from "@homegame/common/dist/interface/user-debt.interface";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DebtInfoDialogComponent from "./components/debt-info-dialog.component";
import { useQuery } from "@tanstack/react-query";
import InlineLoadingComponent from "../core/components/inline-loading.component";
import AccumulatedDebtInterface from "@homegame/common/dist/interface/accumulated-debt.interface";
import TableInterface from "@homegame/common/dist/interface/table.interface";

const PAGE_SIZE = 5;

const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      minWidth: "100%",
      paddingLeft: 0,
      height: "100%",
      "& .MuiDataGrid-footerContainer": {
        border: "none",
      },
      "& .MuiDataGrid-cell": {
        border: "none",
        padding: 0,
        minWidth: 200,
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "none",
      },
      "& .MuiDataGrid-root ": {
        border: "none",
      },
    },
    table: {
      "& .MuiDataGrid-row": {
        borderBottom: "1px solid rgba(255,255,255,.6)",
        paddingBottom: 10,
      },
    },
    title: {
      color: "#47528c",
      fontSize: 14,
      fontWeight: 400,
      textShadow: "none",
      // display: "inline-block",
      // width: "100%",
    },
    heading: {
      fontSize: 10,
      width: "100%",
    },
    value: {
      width: "100%",
      fontSize: 12,
      color: "#fff",
      textShadow: "none",
      fontWeight: 300,
      "& svg": {
        fontSize: 14,
        marginBottom: -3,
        color: "#47528c",
      },
    },
    price: {
      color: "#fff",
      textShadow: "none",
      fontSize: 30,
      textAlign: "center",
    },
    btnsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      paddingTop: 5,
      paddingLeft: 10,
      paddingRight: 10,
      "& button": {
        textShadow: "none",
        minWidth: 80,
      },
    },
  }),
);

interface UserDebtsListProps {
  type?: "users" | "to-user";
  pageSize?: number;
  height?: number | string;
  onDebtChange?: () => void;
}

export default function UserDebtsListComponent({
  type,
  pageSize = PAGE_SIZE,
  height,
  onDebtChange,
}: UserDebtsListProps) {
  const classes = useStyles({});
  const userDebtsService = new UserDebtsService();
  const { showToast } = useToast();

  const { user } = useAuth();
  const { transactionSocket } = useSocket();

  const [refreshTrigger, setRefreshTrigger] = useState(1);

  let tableFilters: UserDebtFilterInterface = {
    take: pageSize,
    skip: 0,
    status: [
      UserDebtStatus.NEW,
      UserDebtStatus.PENDING,
      UserDebtStatus.PAID,
      UserDebtStatus.PARTIALLY_PAID,
      UserDebtStatus.PARTIALLY_SETTLED,
    ],
  };

  if (type === "users") {
    tableFilters.fromUserId = user?.id;
  } else if (type === "to-user") {
    tableFilters.toUserId = user?.id;
  }

  const { data: debtsData, refetch } = useQuery({
    queryKey: [`debtsHistoryItems`, tableFilters],
    queryFn: () => userDebtsService.listByGame(tableFilters),
    initialData: [[], 0],
  });

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    actionType: "approve" | "decline" | "pay";
    debtItem: AccumulatedDebtInterface | undefined;
  }>({
    open: false,
    actionType: "pay",
    debtItem: undefined,
  });

  const handleDialogOpen = (
    debtListItem: AccumulatedDebtInterface,
    actionType: "approve" | "decline" | "pay",
  ) => {
    setDialogState({
      open: true,
      actionType: actionType,
      debtItem: debtListItem,
    });
  };

  const tableRef = useRef(null);

  const OptionsMenu = ({ debt }: { debt: AccumulatedDebtInterface }) => {
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [debtInfoOpen, setDebtInfoOpen] = useState(false);

    const handleOptionsMenuClick = (event: Event | React.SyntheticEvent) => {
      setOptionsMenuOpen(true);
      // @ts-ignore
      setAnchorEl(event.currentTarget);
    };

    const handleOptionsMenuClose = () => {
      setOptionsMenuOpen(false);
      setAnchorEl(null);
    };

    const handleButtonClick = (
      debtListItem: AccumulatedDebtInterface,
      actionType: "approve" | "decline" | "pay",
      event: Event | React.SyntheticEvent,
    ) => {
      handleOptionsMenuClick(event);
      handleDialogOpen(debtListItem, actionType);
    };

    const handleDebtDialogClickOpen = () => {
      setDebtInfoOpen(true);
      handleOptionsMenuClose();
    };

    const handleDebtDialogClose = () => {
      setDebtInfoOpen(false);
    };

    return (
      <>
        <IconButton
          style={{ filter: "none" }}
          onClick={handleOptionsMenuClick}
          color="primary"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={optionsMenuOpen}
          onClose={handleOptionsMenuClose}
        >
          {user?.id === debt.fromUserId &&
            debt.status === UserDebtStatus.NEW && (
              <MenuItem onClick={(e) => handleButtonClick(debt, "pay", e)}>
                <ListItemIcon>
                  <DoneIcon color="success" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Pay</ListItemText>
              </MenuItem>
            )}
          {user?.id === debt.toUserId &&
            debt.status === UserDebtStatus.PENDING && (
              <MenuItem onClick={(e) => handleButtonClick(debt, "approve", e)}>
                <ListItemIcon>
                  <DoneIcon color="success" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Accept</ListItemText>
              </MenuItem>
            )}

          {user?.id === debt.toUserId &&
            debt.status === UserDebtStatus.PENDING && (
              <MenuItem onClick={(e) => handleButtonClick(debt, "decline", e)}>
                <ListItemIcon>
                  <ClearIcon color="error" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Decline</ListItemText>
              </MenuItem>
            )}

          <MenuItem onClick={handleDebtDialogClickOpen}>
            <ListItemIcon>
              <InfoOutlinedIcon color="info" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Info</ListItemText>
          </MenuItem>
        </Menu>

        <DebtInfoDialogComponent
          debt={debt}
          handleDebtDialogClose={handleDebtDialogClose}
          type={type}
          open={debtInfoOpen}
        />
      </>
    );
  };

  const getTableName = (table?: TableInterface) => {
    if (!table) return "";
    if (!table.deletedAt) return table.name;
    return (
      <>
        {`${table.name}`}
        <span style={{ color: "red" }}> (archived)</span>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "",
      flex: 1,
      colSpan: 2,
      hideable: false,
      sortable: false,
      headerClassName: "hidden",
      renderCell: (params: GridRenderCellParams) => {
        const debt: AccumulatedDebtInterface = params.row;

        return (
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <Typography className={classes.title}>
                {type === "users" && `Debt to ${debt.toUser?.name}`}
                {type === "to-user" && `${debt.fromUser?.name} owes me`}
              </Typography>
              <Typography className={classes.value}>
                In {getTableName(debt.table)}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              justifyContent="space-between"
              display="flex"
              alignItems="center"
            >
              <DebtStatus status={debt.status} showIcons />
            </Grid>

            <Grid
              item
              xs={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={classes.price}>
                {formatCurrency(debt.accumulatedDebt, debt.currency)}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <OptionsMenu debt={debt} />
            </Grid>
          </Grid>
        );
      },
    },
  ];

  useEffect(() => {
    const events = ["transactionUpdated", "transactionCreated"];
    if (transactionSocket) {
      events.forEach((eventName) => {
        transactionSocket.on(eventName, (data) => {
          if (
            [data.transaction.fromUserId, data.transaction.toUserId].includes(
              user?.id,
            )
          ) {
            // @ts-ignore
            if (tableRef.current) tableRef.current?.triggerRefresh();
          }
        });
      });

      return () => {
        events.forEach((eventName) => {
          transactionSocket.off(eventName);
        });
      };
    }
  }, [transactionSocket]);

  const getRowId = (row: UserDebtInterface) =>
    row.fromUserId.toString() +
    row.toUserId.toString() +
    row.tableId.toString();

  const onConfirmationClose = () =>
    setDialogState({
      open: false,
      actionType: "pay",
      debtItem: undefined,
    });

  const onPaymentConfirmed = async () => {
    try {
      if (dialogState.actionType === "decline") {
        await userDebtsService.declineTableDebt({
          ...dialogState.debtItem!,
          amount: dialogState.debtItem?.accumulatedDebt!,
        });
        showToast("Debt declined!", "success");
      } else if (dialogState.actionType === "approve") {
        await userDebtsService.approveTableDebt({
          ...dialogState.debtItem!,
          amount: dialogState.debtItem?.accumulatedDebt!,
        });
        showToast("Debt approved!", "success");
      } else if (dialogState.actionType === "pay") {
        await userDebtsService.payTableDebt({
          ...dialogState.debtItem!,
          amount: dialogState.debtItem?.accumulatedDebt!,
        });
        showToast("Debt payed!", "success");
      }
    } catch (e) {
      showToast((e as Error).message, "error");
    } finally {
      setRefreshTrigger(refreshTrigger + 1);
      onConfirmationClose();
      await refetch();
      if (onDebtChange) onDebtChange();
    }
  };

  const NoItemsText = () => {
    return (
      <>
        <img
          alt="home game manager loader"
          width="100"
          height="100"
          src="/cards.svg"
          style={{
            filter: "opacity(0.3)",
            margin: "0 auto",
            display: "block",
            width: "auto",
            height: "25svh",
          }}
        />
        <Typography textAlign="center" fontSize={14} display="block">
          You have no debts
        </Typography>
      </>
    );
  };

  const getConfirmationText = () => {
    let text = `Are you sure you want to ${dialogState.actionType}`;

    if (["approve", "decline"].includes(dialogState.actionType))
      text += ` payment from ${dialogState.debtItem?.fromUser?.name}`;

    let tableName = dialogState.debtItem?.table?.name;
    if (dialogState.actionType === "pay") {
      text += ` to ${dialogState.debtItem?.toUser?.name}`;
    }

    text += ` in table ${tableName}?`;
    return text;
  };

  return (
    <Grid item xs={12} className={classes.wrap}>
      <DataGrid
        autoHeight={!height}
        density="comfortable"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: tableFilters.take,
            },
          },
        }}
        columnHeaderHeight={0}
        rows={debtsData.length ? debtsData[0] : []}
        getRowHeight={() => "auto"}
        columns={columns}
        getRowSpacing={() => ({ top: 0, bottom: 10 })}
        disableDensitySelector
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: NoItemsText,
          loadingOverlay: InlineLoadingComponent,
          // row: CustomGridRow,
        }}
        hideFooterSelectedRowCount
        showColumnVerticalBorder={false}
        showCellVerticalBorder={false}
        hideFooter={!debtsData[0].length}
        getRowId={getRowId}
        sx={{
          height,
          width: "100%",
          "& .MuiDataGrid-overlayWrapper": {
            height: "200px !important",
            position: "absolute",
            top: 0,
            left: 0,
          },
        }}
      />

      <ConfirmationDialogComponent
        text={getConfirmationText()}
        open={dialogState.open}
        onClose={onConfirmationClose}
        actionText={`${dialogState.actionType}`}
        action={onPaymentConfirmed}
      />
    </Grid>
  );
}
